import React from 'react'
import './css/Faqs.css'
import Faqs_first_block from '../components/all-pages-first-block/Faqs_first_block'

const Faqs = () => {
  return (
    <div>
      <Faqs_first_block />
       
       <div className="faqs-block">
          <h1>Frequently Asked Questions</h1>
          <div className="question-box">
          <div className="question">
          <h4>How your billing service will increase my income?</h4>
          <p>Our staff can submit “clean” claims with a 98% acceptance rate, resulting in quicker payments. Furthermore, practices often lose a significant amount of income <br /> due to unpaid insurance claims that they simply do not have time to follow up on. We can do that for you!</p>
          </div>
          <div className="question">
          <h4>Will I no longer need a billing staff if I choose to use your services?</h4>
          <p>This primarily depends upon your practice size. You may no longer need billing staff, but having an administrative team to continue managing authorizations, <br /> filing, and scheduling may still be essential and/or desired. However, with us handling your billing needs, your team will instead be able to focus on their other <br /> tasks more the word efficiently.</p>
          </div>
          <div className="question">
          <h4>How do you access my software?</h4>
          <p>In cases of online software, you provide us with the login credentials. For server-based software, we use a remote access app such as LogMeIn, Team Viewer, <br /> etc.</p>
          </div>
          <div className="question">
          <h4>Will I remain informed about the billing aspects of my practice?</h4>
          <p>Yes! We work remotely with your software; you will still have access to financial reports and can check claim status and patient account balances.</p>
          </div>
          <div className="question">
          <h4>Are you HIPAA compliance?</h4>
          <p>Yes! We provide a Business Associate Agreement to clients that outlines our HIPAA policies. All our staff are required to abide by HIPAA guidelines regarding <br /> patient privacy and protected health information (PHI).</p>
          </div>
          <div className="question">
          <h4>How do I send you the data?</h4>
          <p>There are multiple ways you can send your data to us. Depending upon your current EHR system, we may be able to retrieve the data from the medical records <br /> directly. You can also send us your superbills via fax or online services we use that are secure and HIPAA compliance.</p>
          </div>
          <div className="question">
          <h4>Do the payments come to me directly?</h4>
          <p>Yes, the insurance companies mail payments directly to you or deposit them electronically to your bank account.</p>
          </div>
          <div className="question">
          <h4>Can I reach someone by phone if I have any questions?</h4>
          <p>Yes! We assign you a dedicated billing manager who is available via email and phone during business hours</p>
          </div>
          <div className="question">
          <h4>Do you handle authorizations and verify benefits?</h4>
          <p>Yes, we obtain information regarding co-pay/coinsurance amounts, deductible information, etc. We can also handle pre-authorizations. For more information, <br /> please check our Prices page.</p>
          </div>
          <div className="question">
          <h4>Are there any additional charges?</h4>
          <p>Our fee covers comprehensive insurance billing. However, we do have optional services that will incur additional costs, such as credentialing, verification of <br /> benefits, pre-authorizations, and old A/R collections.</p>
          </div>
          <div className="question">
          <h4>Do you have a long-term contract?</h4>
          <p>We have a month-to-month contract which you can terminate at any time without penalty.</p>
          </div>
          <div className="question">
          <h4>How do I pay Ash Tech USA?</h4>
          <p>Each month, you will receive an invoice from ASH TECH USA via email, you can pay your bill online via the secure portal. We accept major credit cards and e- <br />checks.</p>
          </div>

          
          </div>
          
       </div>

    </div>
  )
}

export default Faqs